export function useInvitationService() {
  const { isUserAuthenticated } = useAuthService();
  const {
    navigateUserBasedOnRole,
    navigateToDashboardApp,
    navigateToSupportAdminApp,
    navigateToAdminApp
  } = useInterAppNavigationService();
  const isInvitationExpiredModal: any = useState('invite-expire', () => false);
  const invitePreFill: any = useState('invite-prefill', () => false);
  const inviteParams: any = useState('invite-params', () => {
    return {
      orgId: '',
      context: '',
      classId: '',
      invitationId: '',
      invitationUrl: ''
    };
  });
  const inviteDetails: any = useState('invite-details', () => false);
  const userSpaceCount: any = useState('invite-userSpaceCount', () => null);
  const enabledProviders: any = useState('invite-enabledProviders', () => null);

  /**
   * Initializes the invitation service with the provided values.
   * @param orgIdValue - The organization ID.
   * @param contextValue - The context value.
   * @param classIdValue - The class ID.
   * @param invitationIdValue - The invitation ID.
   */
  const initService = (
    orgIdValue: string,
    contextValue: string,
    classIdValue: string,
    invitationIdValue: string
  ) => {
    const invitationUrl =
      orgIdValue && contextValue && classIdValue && invitationIdValue
        ? `${orgIdValue}/${contextValue}/${classIdValue}/invitation/${invitationIdValue}`
        : '';
    setInviteParams({
      orgId: orgIdValue,
      context: contextValue,
      classId: classIdValue,
      invitationId: invitationIdValue,
      invitationUrl: invitationUrl
    });
  };

  /**
   * Navigates to the teacher dashboard invitation URL.
   * If the user space count is not greater than or equal to 0,
   * it fetches the user ID and sets it in the inviteDetails object.
   * Create the invitation dashboard url and navigate to Dashboard
   * App.
   *
   * @param schoolId - Unique identifier of the school.
   */
  const navigateTeacherDashboardInvitationUrl = async (schoolId: string) => {
    const inviteParams = getInviteParams();
    const inviteDetails = getInviteDetails();

    // Get the user space count.
    const userSpaceCount = getUserSpaceCount();
    if (!userSpaceCount) {
      inviteDetails.userId = await $fetch(
        '/apigateway/userId/' + inviteParams.orgId
      ).then((res: any) => {
        return res.userId;
      });
      setInviteDetails(inviteDetails);
    }

    // Create the teacher dashbaord invitation URL.
    let url =
      schoolId +
      '/' +
      inviteParams.context +
      '/' +
      inviteParams.classId +
      '/' +
      inviteParams.invitationId +
      '/accept';
    url += `?classKey=${inviteDetails.classKey}&schoolKey=${inviteDetails.schoolKey}&dlsOrgId=${inviteParams.orgId}`;
    if (inviteDetails.userId) {
      url += `&serverPushConnectionId=${inviteDetails.userId}`;
    }

    // Navigate to the Dashboard App.
    navigateToDashboardApp(['invitation', url]);
  };

  /**
   * Navigates to the learner dashboard invitation URL.
   * If the user has no space count till yet, it fetches the space
   * count before navigating.
   * Create the invitation dashboard url and navigate to Dashboard
   * App.
   *
   * @param schoolId - Unique identifier of the school.
   */
  const navigateLearnerDashboardInvitationUrl = async (schoolId: string) => {
    const inviteParams = getInviteParams();
    const inviteDetails = getInviteDetails();

    // Get the user space count.
    let userSpaceCount = getUserSpaceCount();
    if (!userSpaceCount) {
      userSpaceCount = await $fetch('/apigateway/getSpaceCount').then(
        (res: any) => {
          return res.count;
        }
      );
      setUserSpaceCount(userSpaceCount);
    }

    // Create the learner dashbaord invitation URL.
    let url =
      schoolId +
      '/' +
      inviteParams.context +
      '/' +
      inviteParams.classId +
      '/' +
      inviteParams.invitationId +
      '/accept';
    url += `?dlsOrgId=${inviteParams.orgId}&classKey=${inviteDetails.classKey}`;
    if (!(inviteDetails.userId || userSpaceCount > 0)) {
      url += '&new=true';
    }

    // Navigate to the Dashboard App.
    navigateToDashboardApp(['invitation', url]);
  };

  /**
   * Navigates the user to the appropriate dashboard based on their role.
   * @param userData - The user data containing the role information.
   */
  const navigateUserToInvitationDashboard = (userData: any) => {
    if (userData.role === 'student') {
      navigateToDashboardApp(['invitation', 'main']);
    } else if (userData.role === 'superadmin') {
      navigateToSupportAdminApp(['dashboard']);
    } else if (userData.role === 'teacher') {
      navigateToDashboardApp(['invitation', 'main']);
    } else if (userData.role === 'admin') {
      navigateToAdminApp(['invitation', 'main']);
    }
  };

  /**
   * Retrieves the details of an invitation.
   *
   * @returns {Promise<any>} A promise that resolves to the invitation
   * details.
   */
  const getInvitationDetails = async () => {
    const inviteParams = getInviteParams();
    return await $fetch('/apigateway/' + inviteParams.invitationUrl);
  };

  /**
   * Get details of the class by class key.
   *
   * @param classKey - Unique key of the class, used to join a class.
   * @returns - A promise that resolves to class details.
   */
  const getClassByClassKey = async (
    classKey: string
  ): Promise<{
    id: string;
    schoolId: string;
    name: string;
    usersCount: number;
    isDeleted: boolean;
    classLifecyclePolicy: {
      startDate: number;
      endDate: number;
    };
    classEnrollmentPolicy: {
      classKey: string;
    };
    organizationType: {
      organization: string;
    };
  }> => {
    return await $fetch('/apigateway/class-details-using-class-key', {
      method: 'GET',
      query: {
        classKey
      }
    });
  };

  const getGigyaUserInfo = async (email: string) =>
    await $fetch('/apigateway/gigya/getUserInfo?email=' + email);

  /**
   * Processes the invitation for a user.
   *
   * @param orgIdValue - The organization ID.
   * @param contextValue - The context value.
   * @param classIdValue - The class ID.
   * @param invitationIdValue - The invitation ID.
   */
  const processInvite = async (
    orgIdValue: string,
    contextValue: string,
    classIdValue: string,
    invitationIdValue: string
  ) => {
    initService(orgIdValue, contextValue, classIdValue, invitationIdValue);
    let inviteDetailsResponse: any;
    try {
      //get invite details
      inviteDetailsResponse = await getInvitationDetails();

      //get user data
      const authResponse: any = await isUserAuthenticated();
      const userData = authResponse.user;

      // handle logged in users
      if (userData) {
        handleLoggedInUser(inviteDetailsResponse, userData);
      } else {
        // for user not logged in currently
        handleUsersNotLoggedIn(inviteDetailsResponse);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Validates the class invitation.
   * Invalid invitation: store the error in session and return true error object.
   * Valid invitation: return false error object.
   *
   * @param inviteDetailsResponse - Invitation Details
   * @param userData - User Information
   * @returns An Object with error details
   */
  const validateInvitation = (
    inviteDetailsResponse: any,
    classDetails: {
      id: string;
      schoolId: string;
      name: string;
      usersCount: number;
      isDeleted: boolean;
      classLifecyclePolicy: {
        startDate: number;
        endDate: number;
      };
      classEnrollmentPolicy: {
        classKey: string;
      };
      organizationType: {
        organization: string;
      };
    },
    userData: any
  ):
    | {
        isInvalid: false;
      }
    | {
        isInvalid: true;
        errorCode:
          | 'CLASS_FULL'
          | 'CLASS_ENDED'
          | 'UNKNOWN_ERROR'
          | 'CLASS_DELETED';
      } => {
    // Boolean flags to check if invitation is invalid.
    const isMismatchedEmail = inviteDetailsResponse.email !== userData.email;
    const isInvalidStatus = inviteDetailsResponse.status !== 'pending';
    const isInvalidRole =
      userData.role === 'admin'
        ? inviteDetailsResponse.role !== 'teacher'
        : inviteDetailsResponse.role !== userData.role;

    // If any of the boolean flag is true, return true.
    if (isMismatchedEmail || isInvalidStatus || isInvalidRole) {
      return {
        isInvalid: true,
        errorCode: 'UNKNOWN_ERROR'
      };
    }

    // Validate the class end date for student
    if (
      userData.role === 'student' &&
      classDetails.classLifecyclePolicy.endDate <
        inviteDetailsResponse.currentTimestamp
    ) {
      return {
        isInvalid: true,
        errorCode: 'CLASS_ENDED'
      };
    }

    // Get user limit based on user's role
    const userLimit =
      userData.role === 'student'
        ? useRuntimeConfig().public.onboarding.learnerLimitInClass
        : useRuntimeConfig().public.onboarding.teacherLimitInClass;

    // Check if class is full
    if (classDetails.usersCount >= userLimit) {
      return {
        isInvalid: true,
        errorCode: 'CLASS_FULL'
      };
    }
    // Check if class is deleted
    if (classDetails.isDeleted) {
      return {
        isInvalid: true,
        errorCode: 'CLASS_DELETED'
      };
    }

    // Return false if invitation is valid
    return { isInvalid: false };
  };

  /**
   * Processes the invitation for a logged in user.
   *
   * Steps:
   * - Get class details.
   * - Validate the invitation.
   * - Navigate to the user dashboard invitation page.
   *
   *
   * @param inviteDetails - Details of the invitation.
   * @param userData - User information.
   */
  const handleLoggedInUser = async (inviteDetails: any, userData: any) => {
    /**
     * If invitation is expired or revoked, store the error in the
     * session and navigate to user dashboard.
     */
    if (
      (inviteDetails.code === 'INVITATION_EXPIRED' &&
        inviteDetails.status === 'expire') ||
      inviteDetails.status === 'revoke'
    ) {
      // This will be read on the user dashboard to show an error modal
      sessionStorage.setItem(
        'cupa.c1.classInvitationError.inviteExpired',
        'true'
      );

      navigateUserBasedOnRole(userData.role);
      return;
    }

    const classDetails = await getClassByClassKey(inviteDetails.classKey);

    // Validate the invitation.
    const validationResponse = validateInvitation(
      inviteDetails,
      classDetails,
      userData
    );
    if (validationResponse.isInvalid) {
      /**
       * Handle the errors
       * - Set the error flag in the session storage
       * - Navigate to the appropriate route.
       */
      if (validationResponse.errorCode === 'CLASS_ENDED') {
        // This will be read on the user dashboard to show an error modal
        sessionStorage.setItem(
          'cupa.c1.classInvitationError.classEnded',
          'true'
        );
      } else if (
        validationResponse.errorCode === 'CLASS_FULL' &&
        userData.role === 'student'
      ) {
        // This will be read on the class full page (Dashboard App).
        sessionStorage.setItem(
          'cupa.c1.classInvitationError.classFull',
          'true'
        );
        // Navigate to `invitation/classFull`
        navigateToDashboardApp(['invitation', 'classFull']);
        return;
      } else if (validationResponse.errorCode === 'CLASS_DELETED') {
        sessionStorage.setItem(
          'cupa.c1.classInvitationError.classDeleted',
          'true'
        );
      }

      // For other scenarios, navigate to User Dashboard.
      navigateUserBasedOnRole(userData.role);
      return;
    }

    setInviteDetails(inviteDetails);

    // Navigate user to dashboard for invitation.
    if (inviteDetails.role === 'teacher') {
      navigateTeacherDashboardInvitationUrl(classDetails.schoolId);
    } else {
      navigateLearnerDashboardInvitationUrl(classDetails.schoolId);
    }
  };

  /**
   * Handles the logic for users who are not logged in.
   *
   * @param inviteDetailsResponse - The response containing the invitation details.
   * @returns {Promise<void>}
   */
  const handleUsersNotLoggedIn = async (inviteDetailsResponse: any) => {
    // for user not logged in currently
    if (isInvitationExpiredOrRevoked(inviteDetailsResponse)) {
      initService('', '', '', '');
      showInvitationExpiredPopup();
    } else {
      setInviteDetails(inviteDetailsResponse);
      //check if user exists in gigya
      const gigyaUserInfo: any = await getGigyaUserInfo(
        inviteDetailsResponse.email
      );
      if (gigyaUserInfo?.exists) {
        handleExistingUser(gigyaUserInfo);
      } else {
        handleNewUser(inviteDetailsResponse);
      }
    }
  };

  const isInvitationExpiredOrRevoked = (inviteDetailsResponse: any) => {
    return (
      (inviteDetailsResponse.status === 'expire' &&
        inviteDetailsResponse.code === 'INVITATION_EXPIRED') ||
      inviteDetailsResponse.status === 'revoke'
    );
  };

  const handleExistingUser = (gigyaUserInfo: any) => {
    //if user is registered via site
    setInvitePreFill(true);
    if (gigyaUserInfo.info.socialProviders.indexOf('site') > -1) {
      navigateTo({ path: '/login' });
    } else {
      //if user is not registered via (facebook only) or (google only), then both facebook and google enabled by default
      let enabledProviders = 'facebook,googleplus';
      if (gigyaUserInfo.info.socialProviders === 'facebook') {
        // if user is registered via facebook only
        enabledProviders = 'facebook';
      } else if (gigyaUserInfo.info.socialProviders === 'googleplus') {
        // if user is registered via google only
        enabledProviders = 'googleplus';
      }
      setEnabledProviders(enabledProviders);
      navigateTo({ path: '/social-login' });
    }
  };

  const handleNewUser = (inviteDetailsResponse: any) => {
    //for users not registered in gigya redirect to the registration screen corresponding to the invitation role
    setInvitePreFill(true);
    if (inviteDetailsResponse.role === 'teacher') {
      navigateTo({ path: '/register-teacher' });
    } else {
      navigateTo({ path: '/register-learner' });
    }
  };

  /**
   * Shows the invitation expired popup and navigates to the home page.
   */
  const showInvitationExpiredPopup = async () => {
    setInvitationExpiredModal(true);
    navigateTo('/home');
  };

  /**
   * Handles the login process for a user.
   *
   * @param userData - The user data.
   */
  const handleLogIn = (userData: any) => {
    const inviteDetails = getInviteDetails();
    const inviteParams = getInviteParams();
    const userSpaceCount = getUserSpaceCount();
    //if Child account managed by parent
    if (userData.hasParent || !userData.email) {
      navigateUserBasedOnRole(userData.role);
      return;
    }
    //In case if the learner came from invitation email,after login navigate to dashboard invitation route
    if (
      inviteParams.invitationUrl &&
      userData.email == inviteDetails.email &&
      inviteDetails.status == 'pending'
    ) {
      handleInvitationUrl(userData, inviteDetails);
    } else {
      handleNormalLogin(userData, userSpaceCount);
    }
  };

  /**
   * Handles the invitation URL based on the user's role and invite details.
   * If the user is a student and the invite details specify a student role, it navigates to the learner dashboard invitation URL.
   * If the user is a student and the invite details specify a role other than student, it navigates based on the user's role.
   * If the user is a teacher or admin and the invite details specify a teacher role, it navigates to the teacher dashboard invitation URL.
   * If the user is a teacher or admin and the invite details specify a role other than teacher, it navigates based on the user's role.
   * @param userData The user data.
   * @param inviteDetails The invitation details.
   */
  const handleInvitationUrl = async (userData: any, inviteDetails: any) => {
    // Get class details for class validation.
    const classDetails = await getClassByClassKey(inviteDetails.classKey);

    if (classDetails.isDeleted) {
      // Set flag in the session storage and navigate to the dashboard,
      // the flag be read on the user dashboard to show an error modal
      sessionStorage.setItem(
        'cupa.c1.classInvitationError.classDeleted',
        'true'
      );
      navigateUserBasedOnRole(userData.role);
      return;
    }

    // Get user limit based on user's role
    const userLimit =
      userData.role === 'student'
        ? useRuntimeConfig().public.onboarding.learnerLimitInClass
        : useRuntimeConfig().public.onboarding.teacherLimitInClass;

    // Check if class is full
    if (classDetails.usersCount >= userLimit) {
      // Navigate to `invitation/classFull`.
      if (userData.role === 'student') {
        // Set flag in the session storage, this will be read on the class full page (Dashboard App).
        sessionStorage.setItem(
          'cupa.c1.classInvitationError.classFull',
          'true'
        );
        navigateToDashboardApp(['invitation', 'classFull']);
        return;
      }

      navigateUserBasedOnRole(userData.role);
      return;
    }

    if (userData.role === 'student' && inviteDetails.role === 'student') {
      // Validate class end date.
      if (
        classDetails.classLifecyclePolicy.endDate <
        inviteDetails.currentTimestamp
      ) {
        // Set flag in the session storage and navigate to the dashboard,
        // the flag be read on the user dashboard to show an error modal
        sessionStorage.setItem(
          'cupa.c1.classInvitationError.classEnded',
          'true'
        );
        navigateUserBasedOnRole(userData.role);
        return;
      }

      navigateLearnerDashboardInvitationUrl(classDetails.schoolId);
      return;
    }

    if (
      inviteDetails.role === 'teacher' &&
      (userData.role === 'teacher' || userData.role === 'admin')
    ) {
      navigateTeacherDashboardInvitationUrl(classDetails.schoolId);
      return;
    }

    navigateUserBasedOnRole(userData.role);
  };

  /**
   * Handles the normal login flow based on the user data and space count.
   * If the user has spaces or a pending invitation, it navigates the user based on their role.
   * Otherwise, it checks for any pending invitations.
   *
   * @param userData - The user data.
   * @param userSpaceCount - The count of spaces the user has.
   */
  const handleNormalLogin = (userData: any, userSpaceCount: any) => {
    //check if it exists in no space, then check pending invitation
    if (userSpaceCount > 0 || getInviteDetails().userId) {
      navigateUserBasedOnRole(userData.role);
    } else {
      checkPendingInvitations(userData);
    }
  };

  /**
   * Checks for pending invitations and navigates the user accordingly.
   * @param userData - The user data.
   */
  const checkPendingInvitations = (userData: any) => {
    $fetch('/apigateway/checkPendingInvitations').then(
      (pendingResponse: any) => {
        // if have pending invitation, enroll in it
        if (pendingResponse?.count > 0) {
          navigateUserToInvitationDashboard(userData);
        } else {
          //if no pending invitation, navigate to dashboard
          navigateUserBasedOnRole(userData.role);
        }
      }
    );
  };

  const setUserSpaceCount = (count: any) => {
    userSpaceCount.value = count;
  };

  const getUserSpaceCount = () => {
    return userSpaceCount.value;
  };

  const getInviteParams = () => {
    return inviteParams.value;
  };

  const setInviteParams = (newVal: any) => {
    inviteParams.value = newVal;
  };

  const getInvitePreFill = () => {
    return invitePreFill.value;
  };

  const setInvitePreFill = (newVal: any) => {
    invitePreFill.value = newVal;
  };

  const getInvitationExpiredModal = () => {
    return isInvitationExpiredModal.value;
  };

  const setInvitationExpiredModal = (newVal: any) => {
    isInvitationExpiredModal.value = newVal;
  };

  const getInviteDetails = () => {
    return inviteDetails.value;
  };

  const setInviteDetails = (newVal: any) => {
    inviteDetails.value = newVal;
  };

  const getEnabledProviders = () => {
    return enabledProviders.value;
  };

  const setEnabledProviders = (newVal: any) => {
    enabledProviders.value = newVal;
  };

  return {
    initService,
    getInviteParams,
    setUserSpaceCount,
    processInvite,
    getInvitePreFill,
    setInviteDetails,
    getInviteDetails,
    getEnabledProviders,
    getInvitationExpiredModal,
    setInvitationExpiredModal,
    handleLogIn
  };
}
