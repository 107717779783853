const { getRoleBaseDashboardPath, constructMicroAppUrl } = useNavigation();
const { checkUserAuthenticatedCache } = useOnboardingAuth();

export default defineNuxtRouteMiddleware(async (to, from) => {
  let navigateToVal;
  // During SSR, if client has an active server session, then redirect to corresponding dashboard page (role-based)
  if (process.server) {
    let isAuthenticated = false;

    const event = useRequestEvent();

    if (event.context.session?.extUserId) {
      isAuthenticated = true;
    }

    console.log('in page-redirection.ts', isAuthenticated);
    console.log('in page-redirection.ts if', event);
    const userRole: string = event.context.session?.role;

    const { redirectUrl, dashboardPath } =
      redirectionUrlBasedOnUserRole(userRole);

    // let user access the page if not logged in
    // if it is a desktop user, then do not redirect
    if (!isAuthenticated || to.query.u) return;
    // if dashboard url not found, then let user access the page
    else if (!dashboardPath) return;
    // construct the full dashboard URL
    else if (to.path !== redirectUrl) {
      return navigateTo(redirectUrl, {
        external: true
      });
    }
  } else if (process.client) {
    const userData = await checkUserAuthenticatedCache();
    console.log('in page-redirection.ts else');
    console.log(userData);
    if (userData && !from.query.u && !from.query.desktop) {
      const userRole: string = userData.role;

      const { redirectUrl, dashboardPath } =
        redirectionUrlBasedOnUserRole(userRole);
      // if dashboard url not found, then let user access the page
      if (!dashboardPath) return;
      // construct the full dashboard URL
      else if (!to.query.u && to.path !== redirectUrl) {
        return navigateTo(redirectUrl, {
          external: true
        });
      }
    }
  }

  ///////////////////////////////////////////////////////////////////
  ///////////////// IELTS route check START ////////////////////////
  /////////////////////////////////////////////////////////////////
  // Redirection on ielts=true query params
  // regoptions is redirected to /register-learner with ielts=true
  // ielts=true is added in /forgot-password
  navigateToVal = ieltsRouteNavigation(to, from);
  if (navigateToVal) return navigateToVal;
  ///////////////////////////////////////////////////////////////////
  ///////////////// IELTS route check END //////////////////////////
  /////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  ///////////////// DESKTOP route check START ////////////////////////
  /////////////////////////////////////////////////////////////////
  // Redirection on desktop=true query params
  // desktop=true is added in /forgot-password
  navigateToVal = desktopRouteNavigation(to, from);
  if (navigateToVal) return navigateToVal;
  ///////////////////////////////////////////////////////////////////
  ///////////////// DESKTOP route check END //////////////////////////
  /////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  ///////////////// User registration route check START /////////////
  /////////////////////////////////////////////////////////////////
  //prevent navigation on direct route hit to register-learner and learner-age restriction
  navigateToVal = learnerAgeCheckRedirection(to, from);
  if (navigateToVal) return navigateToVal;
  ///////////////////////////////////////////////////////////////////
  ///////////////// User registration route check END //////////////
  /////////////////////////////////////////////////////////////////

  //support admin login redirection
  navigateToVal = supportAdminLoginRedirection(to, from);
  if (navigateToVal) return navigateToVal;
});

const ieltsRouteNavigation = (to: any, from: any) => {
  if (
    (to.path == '/regoptions' || to.path == '/learner-age-check') &&
    from.query.ielts
  ) {
    return navigateTo({ path: '/register-learner', query: { ielts: 'true' } });
  } else if (
    to.path == '/forgot-password' &&
    from.query.ielts &&
    !to.query.ielts
  ) {
    return navigateTo({ path: '/forgot-password', query: { ielts: 'true' } });
  } else if (to.path == '/login' && from.query.ielts && !to.query.ielts) {
    return navigateTo({ path: '/login', query: { ielts: 'true' } });
  }
};

const desktopRouteNavigation = (to: any, from: any) => {
  if (to.path == '/regoptions' && (from.query.desktop || from.query.u)) {
    return navigateTo({
      path: '/register-learner',
      query: { desktop: 'true' }
    });
  } else if (
    to.path == '/forgot-password' &&
    (from.query.desktop || from.query.u) &&
    !to.query.desktop
  ) {
    return navigateTo({ path: '/forgot-password', query: { desktop: 'true' } });
  } else if (to.path == '/login' && from.query.desktop && !to.query.desktop) {
    return navigateTo({ path: '/login', query: { desktop: 'true' } });
  }
};

const learnerAgeCheckRedirection = (to: any, from: any) => {
  if (
    (!from.query.ielts &&
      from.path === '/register-learner' &&
      to.path === '/register-learner') ||
    (from.path === '/learner-age-restriction' &&
      to.path === '/learner-age-restriction')
  ) {
    return navigateTo({ path: '/learner-age-check' });
  }
};

const supportAdminLoginRedirection = (to: any, from: any) => {
  if (to.path == '/' && to.query.p && to.query.t && to.query.t == 'saml') {
    return navigateTo({ path: '/okta' });
  } else if (to.path == '/okta' && from.path == '/okta') {
    console.log('Redirecting to home from supportAdminLoginRedirection');
    return navigateTo({ path: '/home' });
  }
};

const redirectionUrlBasedOnUserRole = (userRole: string) => {
  const dashboardPath = getRoleBaseDashboardPath(userRole);

  let appName = useRuntimeConfig().public.microapps.urlContracts.dashboard;

  if (userRole === 'admin')
    appName = useRuntimeConfig().public.microapps.urlContracts.admin;
  else if (userRole === 'superadmin')
    appName = useRuntimeConfig().public.microapps.urlContracts['support-admin'];

  const redirectUrl = constructMicroAppUrl([dashboardPath], appName);

  return { redirectUrl, dashboardPath };
};
