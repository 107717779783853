import validate from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.4.1_@types+node@20.11.30_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__io_tqktnek4c4np633alkjtvv746e/node_modules/nuxt/dist/pages/runtime/validate.js";
export const globalMiddleware = [
  validate
]
export const namedMiddleware = {
  "check-authenticated": () => import("/app/layers/base/middleware/check-authenticated.ts"),
  "common-loader": () => import("/app/apps/onboarding/middleware/common-loader.ts"),
  "lti-onboarding": () => import("/app/apps/onboarding/middleware/lti-onboarding.ts"),
  "page-redirection": () => import("/app/apps/onboarding/middleware/page-redirection.ts"),
  "parent-login": () => import("/app/apps/onboarding/middleware/parent-login.ts"),
  "redirect-home": () => import("/app/apps/onboarding/middleware/redirect-home.ts")
}